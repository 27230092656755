.body-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.page-container {
  flex-grow: 1;
}
