.embed-container {
  width: calc(100vw / 2);
  min-width: 340px;
  margin: 16px auto;
}

.text-container {
  padding-top: 16px;
  padding-bottom: 16px;
  max-width: 1200px;
  width: 80%;
}

.with-bottom-margin {
  margin-bottom: var(--spacing-8);
}
