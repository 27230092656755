.viral-container {
  width: 100%;
}

.game-container {
  text-align: center;
}

.game-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.questions-container,
.answers-container {
  border: 2px solid var(--mui-palette-primary-light);
  border-radius: 12px;
  padding: 40px;
  width: 420px;
}

.questions-container {
  margin-right: 16px;
}

.answers-container {
  margin-left: 16px;
}

@media screen and (max-width: 600px) {
  .questions-container,
  .answers-container {
    padding: 4px;
  }

  .questions-container {
    margin-right: 8px;
  }

  .answers-container {
    margin-left: 8px;
  }
}

.question-item,
.answer-item {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 340px; */
  height: 60px;
  border: 1px solid var(--mui-palette-secondary-light);
  margin-bottom: var(--spacing-8);
  box-shadow: var(--mui-shadows-3);
  border-radius: 12px;
}

.answers-container,
.answer-item {
  border-style: dashed;
}

.question-item:first-child,
.answer-item:first-child {
  margin-top: var(--spacing-8);
}

.answer-correct {
  background-color: var(--mui-palette-secondary-light);
  color: var(--mui-palette-secondary-contrastText);
  border-style: solid;
  transition: background-color 0.3s ease-in;
}

.answer-selected {
  box-shadow: var(--mui-shadows-24);
  border-color: var(--mui-palette-primary-light);
  background-color: var(--mui-palette-primary-light);
  color: var(--mui-palette-primary-contrastText);
  transition: background-color 0.3s ease-in;
}

.dragging {
  background-color: var(--mui-palette-primary-light);
  transition: background-color 0s ease-in;
}

.answer-not-correct {
  cursor: pointer;
}

.win-content {
  width: 300px;
  height: 300px;
  box-shadow: var(--mui-shadows-3);
  border: 1px solid var(--mui-palette-secondary-light);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  margin: 32px auto;
}
